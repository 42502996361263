import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import {
  NavContainer,
  Content,
  MenuIcon,
  NavItemLogo,
  NavItemInter,
  NavItemContainer,
  ButtonSignIn,
  ButtonStart,
  StyledDropdown,
} from './StyledElements';
import Logo from '../../assets/SVG/Logo';
import Bars from '../../assets/SVG/Bars';
import Button from '../../UIComponents/Button';
import Menu from './Menu';
import LanguageSwitcher from '../language/LanguageSwitcher';
import i18n, { localize } from '../language/config';
import DropdownProduct from './DropdownProduct';

// eslint-disable-next-line func-names
const Navbar = function ({ navbarBackground }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [open, setOpen] = useState(false);
  const { language } = i18n;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClickButton = async (link, title) => {
    if (title && window?.analytics) {
      await window.analytics.track('Landing_1 CTA', {
        buttonTitle: title.toLowerCase(),
        block: 'navigation',
        page: window?.location?.pathname === '/' ? 'home' : window?.location?.pathname.replace(/^./, ''),
      });
    }
    if (link) navigate(link);
  };

  return (
    <>
      <NavContainer
        scrollPosition={scrollPosition === 0 ? 1 : scrollPosition}
        backgroundColor={navbarBackground}
      >
        <Content>
          <NavItemContainer>
            <NavItemLogo to="/" language={language}>
              sondage - evolt
              <Logo color="black" />
            </NavItemLogo>
            {/* <StyledDropdown>
              <DropdownProduct />
            </StyledDropdown> */}
            {/* <NavItemInter aria-current="page" to="/templates" language={language}>
              {localize('Templates')}
            </NavItemInter>
            <NavItemInter aria-current="page" to="/pricing" language={language}>
              {localize('Pricing')}
            </NavItemInter> */}
          </NavItemContainer>
          <NavItemContainer>
            {/* <ButtonSignIn>
              <Button
                type="secondary"
                onClick={() => handleClickButton('https://platform.evoltapp.com', 'Se connecter')}
                label={localize('Login')}
              />
            </ButtonSignIn> */}
            <ButtonStart>
              <Button
                type="black"
                onClick={() => handleClickButton('https://platform.evoltapp.com/signup', 'Commencez')}
                label="Commencez"
              />
            </ButtonStart>
            {/* <MenuIcon
              onClick={() => handleTouch()}
            >
              <Bars />
            </MenuIcon>
            <LanguageSwitcher currentLanguage={language} /> */}
          </NavItemContainer>
        </Content>
      </NavContainer>
      {/* <Menu
        language={language}
        isOpen={open}
        onClick={handleClose}
        handleClickPlatform={handleClickButton}
      /> */}
    </>
  );
};

Navbar.propTypes = {
  navbarBackground: PropTypes.string,
};

Navbar.defaultProps = {
  navbarBackground: 'white',
};

export default Navbar;
