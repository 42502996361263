const colors = {
  blocks: {
    darkBlue: '#091732',
    gradientPink: 'linear-gradient(20deg, hsla(5, 100%, 95%, 1), hsla(268, 100%, 95%, 1))',
    gradientBlue: 'linear-gradient(20deg, hsla(218, 100%, 85%, 1), hsla(268, 100%, 85%, 1))',
    gradientWhiteToBlue: 'linear-gradient(180deg, #F6F9FE 0%, #E4ECFB 100%)',
    gradientBlueToPink: 'linear-gradient(126.68deg, #D3E1FF -31.48%, #ECF1F8 42.57%, #FFEAEF 95.46%)',
    gradientPriceBox: 'linear-gradient(96.76deg, #AA62FF 3.57%, #FC3D74 100.23%)',
    white: '#FFFFFF',
    eggWhite: '#F1F4F8',
    Eveiapink: '#FFE7E6',
    lightBlue: '#F5F8FF',
    lightBlue2: '#EFF1FC',
    pink: '#FFE2EA',
    pinkRed: '#FF7094',
    lightPink: '#FFF2F5',
    veryLightPink: '#FFF6F9',
    green: '#B8E986',
    lightGreen: '#E4F3F6',
    lightGreen2: '#F5FCED',
    lightGrey: '#F2F1F7',
    greenTemplate: '#F1FBE7',
    lightBluePurple: '#DEE3F7',
    lightOrange: '#FFEBDB',
    npsBackground: '#EFFAE5',
    gradientLightGrey: '#F5F5F5',
    veryLightOrange: '#FFF8EB',
    carousselLightGreen: '#F1F8F6',
    carousselLightBlue: '#F1F3F8',
    carousselLightYellow: '#F8F7F1',
    carousselLightPurple: '#F2F1F8',
  },
  texts: {
    black: '#26282D',
    grey: '#5D6A76',
    salmon: '#FF8A80',
    white: '#ffffff',
    darkBlue: '#091732',
    blue: '#0555FF',
    darkerBlack: '#091732',
    darkOrange: '#FF9C4C',
    placeholder: '#9EADBA',
    green: '#009787',
    yellow: '#F6CA4E',
    purple: '#AA62FF',
  },
  titles: {
    purple: '#AA62FF',
    darkBlue: '#091732',
    white: '#FFFFFF',
    black: '#26282D',
    blue: '#0555FF',
    darkerBlack: '#091732',
    orangeToPink: 'linear-gradient(96.76deg, #AA62FF, #FC3D74)',
  },
  buttons: {
    blue: '#0555FF',
    darkBlue: '#1E266D',
    white: '#FFFFFF',
    orange: '#FFEDD3',
    carousselLightGreen: 'rgba(0, 151, 135, .2)',
    carousselLightBlue: 'rgba(5, 85, 255, .2)',
    carousselLightYellow: 'rgba(246, 202, 78, .2)',
    carousselLightPurple: 'rgba(170, 98, 255, .2)',
  },
  borders: {
    darkBlue: '#13326C',
    white: '#ffffff',
    blue: '#0555FF',
    grey: '#222B37',
    lightGrey: '#E0E5E9',
    purple: '#AA62FF',
  },
  FormsBox: {
    blue: '#EAF1FF',
    purple: '#FAF1FF',
    orange: '#FFF6EE',
    red: '#FFF1F5',
  },
};

export default colors;
