import styled from 'styled-components';
import Imgix from 'react-imgix';
import colors from '../../../styles/colors';

export const Container = styled.div`
  width: 28px;
  height: 28px;
  margin-left: ${(props) => props.marginLeft}px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
`;

export const Menu = styled.div`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 40px;
  right: 0;
  width: 132px;
  background: ${colors.blocks.white};
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #E0E5E9;
  box-sizing: border-box;
  a:last-child {
    margin-bottom: 0;
  }
  z-index: 1;
`;

export const Lang = styled.a`
  width: 100%;
  height: 24px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
  :hover {
    color: ${colors.texts.blue};
  }
  p {
    margin-left: 12px;
    font-size: 14px;
  }
  @media screen and (max-width: 800px) {
    height: 30px;
  }
`;

export const ImgContainer = styled.div`
  width: 24px;
  height: 24px;
  background: pink;
  box-sizing: border-box;

`;
export const Image = styled(Imgix)`
`;
