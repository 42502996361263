import Imgix from 'react-imgix';
import styled from 'styled-components';
import colors from '../../styles/colors';
import LocalizedLink from '../language/localizedLink';

export const NavContainer = styled.div`
  background: ${(props) => (props.scrollPosition > 300 ? 'white' : props.backgroundColor)};
  height: 85px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  top : 0;
  /* max-width: 1000px; */
  /* margin: 0 auto; */
  position: sticky;
  transition: background 0.5s ease;
  ${(props) => (props.scrollPosition > 200 ? 'box-shadow: 0px 2px 10px rgba(40, 41, 43, 0.05);' : '')};
  
  @media screen and (max-width: 900px) {
    transition: 0.8s all ease;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0;
  justify-content: space-between;
  @media screen and (max-width: 1080px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 834px) {
    padding: 0 20px;
    max-width: 834px;
  }
`;

export const LogoImg = styled.img`
  width: 179px;
  height: 24px;
`;

export const LinkContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MenuIcon = styled.div`
  display: none;
  margin-left: 1rem;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  background: transparent;
  border: none;
  @media screen and (max-width: 834px) {
    display: flex;
  }
`;

export const NavMenu = styled.div`
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  text-align: center;
  font-family: Euclid-Circular;

  @media screen and (max-width: 470px) {
    button {
      display: none;
    }
  }
`;

export const NavItemLogo = styled(LocalizedLink)`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0;
  margin-right: 35px;
`;

export const NavItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NavItem = styled(LocalizedLink)`
  height: 100%;
  margin-top: 6px;
  margin-left: 30px;
  cursor: pointer;
  text-decoration: none;
  color: ${colors.texts.black};
  text-decoration: none;
  text-align: center;
  font-family: Euclid-Circular;
  font-size: 18px;
  @media screen and (max-width: 834px) {
    display: none;
  }
`;

export const NavItemInter = styled(LocalizedLink)`
  height: 100%;
  margin-top: 6px;
  margin-left: 30px;
  cursor: pointer;
  text-decoration: none;
  color: ${colors.texts.black};
  text-decoration: none;
  text-align: center;
  font-family: Euclid-Circular;
  font-size: 18px;
  @media screen and (max-width: 834px) {
    display: none;
  }
`;

export const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999999999;
  overflow: hidden;
  padding: 30px 20px;
  box-sizing: border-box;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

export const MenuNav = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const LinksDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const LinkMenu = styled(LocalizedLink)`
  height: 40px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: ${colors.texts.darkBlue};
  text-decoration: none;
  font-family: Euclid-Circular;
  font-size: 18px;
  line-height: 40px;
`;

export const LinkMenuIntern = styled(LocalizedLink)`
  height: 40px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: ${colors.texts.darkBlue};
  text-decoration: none;
  font-family: Euclid-Circular;
  font-size: 18px;
  line-height: 40px;
`;

// export const LinkMenuInter = styled(Link)`
//   height: 40px;
//   display: flex;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${colors.texts.darkBlue};
//   text-decoration: none;
//   font-family: Euclid-Circular;
//   font-size: 18px;
//   line-height: 40px;
// `;

export const MenuButton = styled.div`
  position: absolute;
  bottom: 30px;
  width: calc(100% - 40px);
  box-sizing: border-box;
`;

export const ButtonSignIn = styled.div`
  @media screen and (max-width: 834px) {
    display: none;
  }
`;

export const ButtonStart = styled.div`
  margin-left: 20px;
`;

export const DropdownProductContainer = styled.div`
  margin-top: 6px;
`;

export const DropdownText = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  
  p {
    color: ${({ show }) => (show ? colors.texts.blue : 'black')} !important;
  }
`;

export const DropdownArrowIcon = styled.div`
  margin-top: 2px;
  transform: ${({ show }) => (show ? 'rotate(180deg)' : 'none')};
  transition: 0.3s ease-in-out;
`;

export const DropdownContainer = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  left: 0;
  top: 85px;
  width: 100%;
  height: 400px;
  background: white;
  position: absolute;
  z-index: 9999;
`;

export const DropdownWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  padding-top: 32px;
  display: flex;
  gap: 20px;
`;

export const DropdownCardContainer = styled.div`
  cursor: pointer;
  width: 100%;
  max-width: 256px;
  min-height: 297px;
  border-radius: 18px;
  height: fit-content;
  box-sizing: border-box;
  border: 1px solid ${colors.borders.lightGrey};
`;

export const IconAppContainer = styled.div`
  margin-top: 25px;
  svg {
    height: 26px;
  }
`;

export const TopCardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -1px;
  margin-left: -1px;
  width: calc(100% + 2px);
  border-radius: 18px 18px 0px 0px;
  height: 144px;
  background: ${({ background }) => background};
`;

export const BottomCardContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 12px 24px 24px 24px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: ${colors.texts.grey};
  }
`;

export const StyledAppImgix = styled.img`
  margin-top: ${(props) => props.marginTop}px;
  width: auto;
  height: ${(props) => props.imageHeight}px;
`;

export const StyledDropdown = styled.div`
  @media(max-width: 834px) {
    visibility: hidden;
  }
`;
