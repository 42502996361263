module.exports = {
  fr: {
    isDefault: true,
    path: 'fr',
    displayName: 'Français',
  },
  en: {
    path: 'en',
    displayName: 'English',
  },
};
