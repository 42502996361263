import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useScrollPercentage } from 'react-scroll-percentage';
import colors from '../../styles/colors';
import {
  Block,
  Content,
  Row,
  Title,
  LinkTxt,
  LinkInterTxt,
  ReadyContainer,
  ReadyTitle,
  ReadySlogan,
  StyledButton,
  StyledImage,
} from './StyledElements';
import LogoFooter from '../../assets/SVG/LogoFooter';
import { WithIntl } from '../language/withIntl';
import i18n, { localize } from '../language/config';
import FooterForms from '../../assets/images/footer-forms.png';

const Footer = function ({ background, backgroundImage, color }) {
  const { language } = i18n;
  const [ref, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0,
  });

  const handleClickButton = async (link, title) => {
    if (title && window?.analytics) {
      await window.analytics.track('Landing_1 CTA', {
        button_title: title.toLowerCase(),
        block: 'footer',
        page: window?.location?.pathname === '/' ? 'home' : window?.location?.pathname.replace(/^./, ''),
        link,
      });
    }
    if (link) navigate(link);
  };

  return (
    <Block background={background} ref={ref}>
      <div style={{
        display: 'flex', justifyContent: 'center', zIndex: 1,
      }}
      >
        <StyledImage src={FooterForms} percentage={(percentage.toPrecision(2)) * 200} />
      </div>
      <ReadyContainer backgroundImage={backgroundImage}>
        <ReadyTitle>N'êtes-vous pas un peu curieux ?</ReadyTitle>
        <div style={{ zIndex: 999 }}>
          <StyledButton label="N’attendez plus" onClick={() => handleClickButton('https://platform.evoltapp.com', 'N’attendez plus')} />
        </div>
      </ReadyContainer>
      <Content>
        <Row>
          <LogoFooter color="white" />
          <LinkInterTxt color={color} to="/legal" language={language}>{localize('© évolt Ltd 2021 Terms & Privacy')}</LinkInterTxt>
        </Row>
        <Row>
          <Title color={color}>{localize('Company')}</Title>
          <LinkInterTxt color={color} to="/legal" language={language}>{localize('Legals')}</LinkInterTxt>
        </Row>
        <Row>
          <Title color={color}>{localize('Follow Us')}</Title>
          <LinkTxt color={color} target="_blank" rel="noopener" href="https://dribbble.com/evolt">Dribbble</LinkTxt>
          <LinkTxt color={color} target="_blank" rel="noopener" href="https://www.facebook.com/evoltdesignchangers/">Facebook</LinkTxt>
          <LinkTxt color={color} target="_blank" rel="noopener" href="https://www.linkedin.com/company/evolt/">Linkedin</LinkTxt>
        </Row>
        <Row>
          <Title color={color}>{localize('Get Started')}</Title>
          <LinkTxt color={color} target="_blank" rel="noopener" href="https://platform.evoltapp.com">{localize('Give it a try')}</LinkTxt>
        </Row>
      </Content>
    </Block>
  );
};

Footer.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
};

Footer.defaultProps = {
  background: colors.blocks.darkBlue,
  color: colors.texts.white,
};

export default WithIntl()(Footer);
