import React from 'react';
import PropTypes from 'prop-types';

export default function Cross({ width, height, title}) {
  return (
    <svg height={height} width={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	  <title>{title}</title>
	  <g fill="none">
		<path d="M18.983 1.017a1.128 1.128 0 0 0-1.633 0L10 8.367l-7.35-7.35a1.128 1.128 0 0 0-1.633 0 1.128 1.128 0 0 0 0 1.633L8.367 10l-7.35 7.35a1.128 1.128 0 0 0 0 1.633c.233.234.466.35.816.35.35 0 .584-.116.817-.35l7.35-7.35 7.35 7.35c.233.234.583.35.816.35.234 0 .584-.116.817-.35a1.128 1.128 0 0 0 0-1.633L11.633 10l7.35-7.35a1.128 1.128 0 0 0 0-1.633z" fill="#091732"/>
	  </g>
    </svg>
  );
};

Cross.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};

Cross.defaultProps = {
  height: '18',
  width: '18',
  color: '#0555FF',
  title: "close"
};