import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuContainer,
  MenuNav,
  NavItemLogo,
  MenuIcon,
  LinksDiv,
  LinkMenu,
  MenuButton,
  LinkMenuIntern,
} from './StyledElements';
import Logo from '../../assets/SVG/Logo';
import colors from '../../styles/colors';
import Cross from '../../assets/SVG/Cross';
import Button from '../../UIComponents/Button';
import { localize } from '../language/config';

const Menu = function ({
  isOpen, onClick, handleClickPlatform, language,
}) {
  return (
    <MenuContainer isOpen={isOpen}>
      <MenuNav>
        <NavItemLogo to="/">
          feedback - evolt
          <Logo color={colors.blocks.darkBlue} />
        </NavItemLogo>
        <MenuIcon onClick={onClick}>
          <Cross />
        </MenuIcon>
      </MenuNav>
      <LinksDiv>
        <LinkMenuIntern aria-current="page" to="/forms" language={language}>Forms</LinkMenuIntern>
        <LinkMenuIntern aria-current="page" to="/livepoll" language={language}>{localize('Livepoll')}</LinkMenuIntern>
        <LinkMenuIntern aria-current="page" to="/brainstorm" language={language}>Wall</LinkMenuIntern>
        <LinkMenuIntern aria-current="page" to="/templates" language={language}>{localize('Templates')}</LinkMenuIntern>
        <LinkMenuIntern aria-current="page" to="/pricing" language={language}>{localize('Pricing')}</LinkMenuIntern>
        <LinkMenu
          href="https://platform.evoltapp.com"
          onClick={() => handleClickPlatform('https://platform.evoltapp.com', 'login')}
        >
          {localize('Login')}
        </LinkMenu>
      </LinksDiv>
      <MenuButton>
        <Button
          width="100%"
          label={localize('FreeTry')}
          onClick={() => handleClickPlatform('https://platform.evoltapp.com', 'free-try')}
        />
      </MenuButton>
    </MenuContainer>
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  handleClickPlatform: PropTypes.func,
  language: PropTypes.string,
};

Menu.defaultProps = {
  isOpen: false,
  onClick: PropTypes.func,
  handleClickPlatform: PropTypes.func,
  language: 'en',
};

export default Menu;
