import React from 'react';
import PropTypes from 'prop-types';


const Play = ({ width, height, color}) => {
	return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none">
        <path d="M16.53 11.1516L8.53 6.1516C8.221 5.9576 7.833 5.9486 7.515 6.1246C7.197 6.3016 7 6.6356 7 6.9996V16.9996C7 17.3636 7.197 17.6976 7.515 17.8746C7.667 17.9576 7.833 17.9996 8 17.9996C8.184 17.9996 8.368 17.9486 8.53 17.8476L16.53 12.8476C16.822 12.6646 17 12.3446 17 11.9996C17 11.6546 16.822 11.3346 16.53 11.1516Z" fill={color}/>
      </g>
    </svg>
	);
};

Play.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

Play.defaultProps = {
  width: '24',
  height: '24',
  color: '#26282D',
};

export default Play;