import colors from '../../styles/colors';

const buttonTypes = {
  main: {
    background: colors.buttons.blue,
    color: colors.buttons.white,
    border: 'transparent',
    boxShadow: 'none',
    backgroundHover: colors.buttons.darkBlue,
  },
  secondary: {
    background: 'white',
    color: colors.texts.black,
    border: '#E0E5E9',
    boxShadow: '0px 2px 4px rgba(41, 41, 43, 0.05)',
    backgroundHover: colors.borders.lightGrey,
  },
  blue: {
    background: '#0555FF',
    color: colors.texts.white,
    border: 'transparent',
    boxShadow: 'none',
    backgroundHover: colors.borders.darkBlue,
  },
  black: {
    background: '#26282D',
    color: colors.texts.white,
    border: 'transparent',
    boxShadow: 'none',
    backgroundHover: '#3C3E42',
  },
};

export default buttonTypes;
