import React from 'react';
import PropTypes from 'prop-types';
import colors from '../styles/colors';
import './layout.css';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = function ({ navbarBackground, children }) {
  return (
    <>
      <Navbar navbarBackground={navbarBackground} />
      <div
        style={{
          margin: '0 auto',
        }}
      >
        <main>{children}</main>
        <Footer
          background={colors.blocks.darkBlue}
          backgroundImage="https://evolt-website.imgix.net/feedback/footer-forms.png?dpr=2"
          color={colors.texts.white}
        />
      </div>
    </>
  );
};

Layout.propTypes = {
  navbarBackground: PropTypes.string,
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
  navbarBackground: 'white',
};

export default Layout;
