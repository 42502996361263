import * as React from "react"
import PropTypes from 'prop-types'

export default function Logo({ width, height, color, title }) {
  return (
    <svg height={height} width={width} viewBox="0 0 74 24" xmlns="http://www.w3.org/2000/svg">
			<g fill="none">
				<path d="M8.849 8.275c3.574 0 6.575 2.315 6.575 6.598 0 .293 0 .532-.027.958H4.674c.136 2.181 1.937 3.618 4.202 3.618 1.91 0 3.192-.851 3.902-2.022l2.292 1.596c-1.228 1.889-3.357 3.086-6.221 3.086-4.148 0-7.122-2.873-7.122-6.917 0-3.91 2.974-6.917 7.122-6.917zm-4.037 5.428h7.584c-.328-1.916-1.91-2.952-3.657-2.952-1.746 0-3.52.983-3.929 2.952h.002z" fill="#26282D"/>
				<path d="M16.324 8.542h3.33l4.146 9.312 4.175-9.312h3.356l-6.194 13.301h-2.646L16.324 8.542z" fill="#26282D"/>
				<path d="M39.244 22.11c-4.037 0-7.148-3.06-7.148-6.918 0-3.857 3.11-6.917 7.148-6.917s7.15 3.06 7.15 6.917c0 3.857-3.112 6.917-7.15 6.917zm0-2.714c2.401 0 4.093-1.836 4.093-4.204 0-2.368-1.692-4.203-4.093-4.203-2.428 0-4.12 1.836-4.12 4.203 0 2.368 1.692 4.204 4.12 4.204z" fill="#26282D"/>
				<path d="M49.421 1.89h3.002v19.953H49.42V1.891z" fill="#26282D"/>
				<path d="M62.382 21.976c-2.62 0-4.666-1.41-4.666-4.416V11.15h-2.838V8.542h2.838V4.844h3.001v3.698h3.902v2.607h-3.902v5.932c0 1.65.737 2.262 2.292 2.262a4.96 4.96 0 0 0 1.61-.267v2.608c-.6.186-1.31.292-2.237.292z" fill="#26282D"/>
				<path d="M69.063 22.05c1.312 0 2.375-1.036 2.375-2.315 0-1.28-1.063-2.316-2.375-2.316s-2.376 1.037-2.376 2.316 1.064 2.316 2.376 2.316z" fill="#26282D"/>
				<path d="M10.249 1.892c0 .398-.106.789-.306 1.136-.2.346-.489.637-.838.843a2.421 2.421 0 0 1-2.329.075L5.701 5.958c.67.341 1.416.519 2.172.518 2.597 0 4.995-2.053 4.995-4.584h-2.62z" fill="#26282D"/>
			</g>
		</svg>
  );
}

Logo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};

Logo.defaultProps = {
  height: '24',
  width: '74',
  color: "#fff",
  title: "Feedback Evolt"
};