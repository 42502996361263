import React from 'react';
import PropTypes from 'prop-types';
import buttonType from './ButtonTypes';
import {
  ButtonContainer,
  PlayIconContainer,
} from './StyledElements';
import Play from '../../assets/SVG/Play';

const Button = function ({
  icon, label, top, onClick, type, width, disabled,
}) {
  const typeButton = buttonType[type] ? buttonType[type] : buttonType.main;
  return (
    <ButtonContainer {...typeButton} disabled={disabled} width={width} onClick={onClick} top={top} type={type}>
      {label}
      {icon && icon === 'play' && (
        <PlayIconContainer>
          <Play />
        </PlayIconContainer>
      )}
    </ButtonContainer>
  );
};

Button.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  top: PropTypes.number,
  type: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'main',
  onClick: () => {},
  disabled: false,
};

export default Button;
