import React, { useState, useRef, useEffect } from 'react';
import ArrowDown from '../../assets/SVG/ArrowDown';
import IconFeedback from '../../assets/SVG/IconFeedback';
import IconLivepoll from '../../assets/SVG/IconLivepoll';
import IconBrainstorm from '../../assets/SVG/IconBrainstorm';
import colors from '../../styles/colors';
import { localize } from '../language/config';

import {
  DropdownProductContainer,
  DropdownText,
  DropdownArrowIcon,
  DropdownContainer,
  DropdownWrapper,
  DropdownCardContainer,
  IconAppContainer,
  BottomCardContainer,
  TopCardContainer,
  StyledAppImgix,
} from './StyledElements';

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

const DropdownCard = function ({ app }) {
  const cardTypes = {
    forms: {
      marginTop: 35,
      imageHeight: 88,
      link: '/forms',
      image: 'quetionnaire-logo.png',
      background: colors.blocks.veryLightPink,
      icon: <IconFeedback />,
      text: localize('Create customizable questionnaires in two clicks.'),
    },
    livepoll: {
      marginTop: 28,
      imageHeight: 120,
      link: '/livepoll',
      image: 'livepoll-logo.png',
      background: colors.FormsBox.blue,
      icon: <IconLivepoll />,
      text: localize('Easily get live feedback during your events and meetings.'),
    },
    wall: {
      marginTop: 26,
      imageHeight: 100,
      link: '/brainstorm',
      image: 'brainstorm-logo.png',
      background: colors.blocks.veryLightOrange,
      icon: <IconBrainstorm />,
      text: localize('Collect feedback on a post-it wall without having to create an account.'),
    },
  };

  return (
    <DropdownCardContainer onClick={() => window.location.replace(cardTypes[app].link)}>
      <TopCardContainer background={cardTypes[app].background}>
        <StyledAppImgix
          marginTop={cardTypes[app].marginTop}
          imageHeight={cardTypes[app].imageHeight}
          src={`https://evolt-website.imgix.net/feedback/${cardTypes[app].image}?dpr=2`}
        />
      </TopCardContainer>
      <IconAppContainer>
        {cardTypes[app].icon}
      </IconAppContainer>
      <BottomCardContainer>
        <p>{cardTypes[app].text}</p>
      </BottomCardContainer>
    </DropdownCardContainer>
  );
};

const DropdownProduct = function () {
  const [show, setShow] = useState(false);

  return (
    <DropdownProductContainer onBlur={() => setShow(false)}>
      <DropdownText onClick={() => setShow(!show)} show={show}>
        <p>{localize('Products')}</p>
        <DropdownArrowIcon show={show}>
          <ArrowDown color={show ? colors.texts.blue : 'black'} />
        </DropdownArrowIcon>
      </DropdownText>
      <DropdownContainer show={show} onClick={() => setShow(false)}>
        <DropdownWrapper>
          <DropdownCard app="forms" />
          <DropdownCard app="livepoll" />
          <DropdownCard app="wall" />
        </DropdownWrapper>
        <div aria-hidden="true" style={{ width: '100%', height: '100vh' }} onClick={() => setShow(false)} />
      </DropdownContainer>
    </DropdownProductContainer>
  );
};
export default DropdownProduct;
