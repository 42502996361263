import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from './config';

// HOC that pre renders a page with the translated language (during build)
// Without this HOC the page will be translated on the client side dynamically
// eslint-disable-next-line import/prefer-default-export
export const WithIntl = () => (WrappedComponent) => {
  // eslint-disable-next-line react/prop-types
  const WrapWithIntl = function ({ pageContext }, props) {
    const addResources = (pc, language) => {
      // passed down from createPages(...) in gatsby-node.js
      if (pc && pc.localeResources) {
        if (!i18next.hasResourceBundle(language, 'translations')) {
          // We only want to add the es.json translations for our /es pages
          i18next.addResourceBundle(language, 'translations', {
            ...pc.localeResources,
          });
        }
      }
    };

    if (pageContext) {
      const currentLanguage = pageContext.locale;
      if (currentLanguage && currentLanguage !== i18next.language) {
        addResources(pageContext, currentLanguage);
        i18next.changeLanguage(currentLanguage);
      } else if (!currentLanguage) {
        addResources(pageContext, 'fr');
        i18next.changeLanguage('fr');
      }
    }
    return (
      <I18nextProvider i18n={i18next}>
        <WrappedComponent {...props} language={i18next.language} />
      </I18nextProvider>
    );
  };
  return WrapWithIntl;
};
