import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import {
  Container,
  Menu,
  Lang,
  Image,
} from './StyledElements';
import languageConfig from '../../../../language-config';
import { localize } from '../config';

const LanguageSwitcher = function ({ currentLanguage, marginLeft }) {
  const [open, setOpen] = useState(false);

  const changeLang = (lang) => {
    setOpen(false);
    if (lang !== currentLanguage) {
      const path = languageConfig[lang].isDefault
        ? '/'
        : `/${lang}${window.location.pathname}`;
      navigate(path);
    }
  };
  return (
    <Container
      marginLeft={marginLeft}
      onClick={() => setOpen(!open)}
    >
      <Image disableSrcSet width={24} height={24} htmlAttributes={{ alt: currentLanguage && currentLanguage === 'fr' ? 'français' : 'english' }} src={`https://evolt-website.imgix.net/feedback/${currentLanguage}.png?dpr=2`} />
      <Menu open={open}>
        <Lang onClick={() => changeLang('fr')}>
          <Image disableSrcSet width={24} height={24} htmlAttributes={{ alt: 'français' }} src="https://evolt-website.imgix.net/feedback/fr.png?dpr=2" />
          <p>{localize('French')}</p>
        </Lang>
        <Lang onClick={() => changeLang('en')}>
          <Image disableSrcSet width={24} height={24} htmlAttributes={{ alt: 'english' }} src="https://evolt-website.imgix.net/feedback/en.png?dpr=2" />
          <p>{localize('English')}</p>
        </Lang>
      </Menu>
    </Container>
  );
};

LanguageSwitcher.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  marginLeft: PropTypes.number,
};

LanguageSwitcher.defaultProps = {
  marginLeft: 16,
};

export default LanguageSwitcher;
