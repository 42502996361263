import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import JsonLd from '../../UIComponents/JsonLd';

const HeaderHtml = function ({
  language, title, description, showWidget,
}) {
  return (
    <div>
      <Helmet>
        <html lang={language} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content="https://evolt-website.imgix.net/feedback/evolt-preview.png" />
        <meta property="og:description" content={description} />
        <meta name="google-site-verification" content="scnqOD6Sf77Xo6hiX101RMh9q3rtdowYpaK7gCkJy9s" />
        <meta charSet="utf-8" />
        <meta httpEquiv="Cache-control" content="No-Cache" />
        <script src="https://fast.wistia.net/assets/external/E-v1.js" async />

        <JsonLd>
          {{
            '@context': 'https://evolt.io/',
            '@type': 'Organization',
            url: 'https://evolt.io/',
            name: 'feedback evolt',
            contactPoint: {
              '@type': 'ContactPoint',
              email: 'jonathan.scanzi@evolt.io',
              contactType: 'Customer service',
            },
          }}
        </JsonLd>
        {showWidget
        && (
        <script type="text/javascript">
          {
            `
            (function (w, d, s, o, f, js, fjs) {
              w["Evolt-Widget"] = o;
              w[o] =
                w[o] ||
                function () {
                  (w[o].q = w[o].q || []).push(arguments);
                };
              (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
              js.id = o;
              js.src = f;
              js.async = 1;
              fjs.parentNode.insertBefore(js, fjs);
            })(window, document, "script", "evolt", "https://evolt-widget.netlify.app/widget.js");
            evolt("init", "62ac4d0d64bbc9001a0c984c");
            `
          }
        </script>
        )}
        <script type="text/javascript">
          {
            `
              window.axeptioSettings = {
                clientId: "62ac4cccf64caf949eccebea",
                cookiesVersion: "landing frenchies-fr",
              };
              
              (function(d, s) {
                var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                e.async = true; e.src = "//static.axept.io/sdk.js";
                t.parentNode.insertBefore(e, t);
              })(document, "script");
            `
          }
        </script>
        <script text="text/javascript">
          {
            `
              (_axcb = window._axcb || []).push(function(sdk) {
                sdk.on('consent:saved', function(choices) { 
                  console.log("window.location.reload()");
                  window.location.reload()
                });
              });

              (_axcb = window._axcb || []).push(function(axeptio) {
                axeptio.on("cookies:complete", function(choices) {
                  console.log("Accept cookies => ", choices);
                  if (choices.segment) {
                    launchSegment();
                  }
                })
              });

              // (_axcb = window._axcb || []).push(function(axeptio) {
              //   axeptio.on("consent:saved", function() {
              //     console.log("window.location.reload()");
              //   })
              // });
              
              function launchSegment () {
                console.log("launchSegment !!");
                !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="p1oyavO6GI0LdGzFDHJvhryI5sAl1Ii3";;analytics.SNIPPET_VERSION="4.15.3";
                analytics.load("${process.env.SEGMENT}");
                window.analytics.page();
                }}();
              }
            `
          }
        </script>
      </Helmet>
    </div>
  );
};

HeaderHtml.propTypes = {
  language: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showWidget: PropTypes.bool,
};

HeaderHtml.defaultProps = {
  showWidget: false,
};

export default HeaderHtml;
