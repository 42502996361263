import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { str as crc32 } from 'crc-32';
import LanguageConfig from '../../../language-config';

i18n.use(initReactI18next).init({
  fallbackLng: 'fr',
  ns: ['translations'],
  defaultNS: 'translations',

  // To investigate react-i18next translation issues enable this
  debug: false,

  interpolation: {
    escapeValue: false,
  },

  react: {
    wait: true,
    useSuspense: false,
    hashTransKey(defaultValue) {
      return crc32(defaultValue);
    },
  },
});

i18n.languages = Object.keys(LanguageConfig);

// eslint-disable-next-line max-len
export const localize = (string, values) => i18n.t(crc32(string), { defaultValue: string, ...values });
export default i18n;
