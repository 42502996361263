import styled from 'styled-components';
import colors from '../../styles/colors';
import Button from '../../UIComponents/Button';
import LocalizedLink from '../language/localizedLink';

export const Block = styled.div`
  overflow: hidden;
  position: relative;
  background: ${(props) => props.background};
  width: 100%;
  height: fit-content;
  box-sizing: border-box;

  @media screen and (max-width: 834px) {
    padding: 0px 20px 64px 20px;
  }
`;

export const Content = styled.div`
  z-index: 999;
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  grid-template-rows: auto;
  gap: 0 1rem;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 20px 100px 20px;
  box-sizing: border-box;
  position: relative;
  justify-content: center;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    background: rgba(224, 229, 233, 0.2);
    width: 100%;
  }

  @media screen and (max-width: 834px) {
    :before {
      width: calc(100% - 40px);
      left: 20px;
    }
  }
`;

export const Row = styled.div`
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & svg {
    margin-bottom: 17px;
  }
  @media screen and (max-width: 834px) {
    grid-column: span 6;
    margin-top: 20px;
  }
`;

export const ReadyContainer = styled.div`
  z-index: 999;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    padding-top: 100px;
    padding-bottom: 150px;
    background-size: 90% auto;
  }

  @media screen and (max-width: 576px) {
    padding-top: 150px;
    padding-bottom: 100px;
  }
`;

export const StyledButton = styled(Button)`
  z-index: 999 !important;
`;

export const ReadyTitle = styled.h3`
  color: ${colors.titles.white};
  font-family: Gustavo-Bold;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  max-width: 340px;
  width: 100%;
`;

export const ReadySlogan = styled.p`
  font-family: Euclid-Circular;
  color: ${colors.titles.white};
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
`;

export const TitleCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Title = styled.p`
  color: ${(props) => props.color};
  font-family: Inter-Regular;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 10px;
  width: 100%;
  @media screen and (max-width: 834px) {
    margin-bottom: 0px;
  }
`;

export const LinkTxt = styled.a`
  text-align: left;
  text-decoration: none;
  font-family: Inter-Regular;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.color};
  opacity: 0.8;
  width: 100%;
  @media screen and (max-width: 834px) {
   line-height: 48px;
  }
`;

export const LinkInterTxt = styled(LocalizedLink)`
  text-align: left;
  text-decoration: none;
  font-family: Inter-Regular;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.color};
  opacity: 0.8;
  width: 100%;
  @media screen and (max-width: 834px) {
   line-height: 48px;
  }
`;

export const StyledImage = styled.img`
  z-index: 1;
  position: absolute;
  max-width: 855px;
  width: 100%;
  top: -${({ percentage }) => percentage}px;
`;
