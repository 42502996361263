import styled from 'styled-components';

export const ButtonContainer = styled.button`
  background: ${(props) => props.background};
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  margin-top: ${(props) => (props.top ? `${props.top}px` : null)};
  border-radius: 8px;
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.border};
  cursor: pointer;
  padding: 0 20px;
  color: ${(props) => props.color};
  text-decoration: none;
  font-size: 14px;
  font-family: Inter-Regular;
  box-shadow: ${(props) => props.boxShadow};
  transition: background 0.3s;
  &:disabled {
    opacity: 0.2;
    color: #ffffff;
  }

  &:hover {
    background: ${(props) => props.backgroundHover};
  }
`;

export const PlayIconContainer = styled.div`
  margin-left: 4px;
  margin-top: 5px;
`;
