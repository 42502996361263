/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import LanguageConfig from '../../../language-config';

const LocalizedLink = function ({ to, language, ...props }) {
  if (language) {
    const { path } = LanguageConfig[language];
    const { isDefault } = LanguageConfig[language];
    const pathTo = isDefault ? to : `/${path}${to}`;

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <GatsbyLink {...props} to={pathTo} />;
  }

  return <></>;
};

LocalizedLink.propTypes = {
  to: PropTypes.string,
  language: PropTypes.string,
};

LocalizedLink.defaultProps = {
  to: '',
  language: '',
};

export default LocalizedLink;
